html, body {
  height: 100vh;
  overflow-y: hidden;
}

.project-full {
  display: flex;
  height: 100vh;
  overflow-y: hidden;
}

.project-left {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  background-color: #000;
  padding: 4rem 0;
  height: calc(100vh - 8rem);
  overflow-y: scroll;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.project-left .thumbnail {
  width: calc(25% - .75px);
  height: calc(12.5vw * 161.31 / 210.88);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-right: 1px dashed #000;
  border-bottom: 1px dashed #000;
  cursor: pointer;
  outline-offset: -.3125rem;
  outline-width: .3125rem;
  outline-style: solid;
  transition: all .2s;
  position: relative;
}

.project-left .thumbnail .contract-page {
  position: absolute;
  bottom: 1rem;
  min-width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  vertical-align: middle;
  background-color: #fff;
  border-radius: 100rem;
}

.project-left .thumbnail .contract-bar {
  position: absolute;
  bottom: calc(1rem + 1rem - .25rem);
  width: calc(100% + 2px);
  left: 0;
  height: .5rem;
  background-color: #fff;
}

.project-left .thumbnail .contract-author {
  position: absolute;
  width: 2rem;
  height: 2rem;
  bottom: 1rem;
  left: calc(50% - 3.25rem);
}

.project-left .thumbnail .contract-bar.first {
  width: calc(50% + 1px);
  left: 50%;
}

.project-left .thumbnail .contract-bar.last {
  width: calc(50% + 1px);
  left: -1px;
}

.project-left .thumbnail .contract-page.other {
  background-color: #ddd;
  top: 1rem;
}

.project-left .thumbnail .contract-author.other {
  top: 1rem;
}

.project-left .thumbnail .contract-bar.other {
  background-color: #ddd;
  top: calc(1rem + 1rem - .25rem);
}

.project-left .thumbnail .contract-bar.first.last {
  display: none;
}

.project-left .thumbnail:nth-child(4n+1) {
  border-right: none !important;
}

.project-left img {
  width: calc(100% - 1.25rem);
  user-select: none;
}

.project-left .full-image {
  width: 100%;
  display: flex;
  align-items: center;
}

.project-left .actions {
  position: absolute;
  bottom: 1rem;
  right: calc(50vw + 3rem);
  height: 3rem;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  transition: all .2s;
}

.project-right {
  width: calc(50% - 2rem);
  padding: 4rem 1rem;
  height: calc(100vh - 8rem);
  overflow-y: scroll;
  background-color: #f2f1ef;
  scroll-behavior: smooth;
}

.split {
  display: flex;
  justify-content: space-between;
}
