.fullscreen {
  background: #eee;
}

.left {
  width: 100%;
  display: flex;
  position: sticky;
  top: 0;
}

.left-menu {
  width: 20rem;
  height: 100vh;
  position: sticky;
  top: 0;
}

.projects {
  display: flex;
  flex-wrap: wrap;
  padding-left: 2rem;
  padding-top: 1rem;
}

.projects > * {
  margin: .5rem;
}
